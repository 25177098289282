import React from 'react';

import Hero from './Components/Hero';
import Header from './Components/Header';
import Projects from './Components/Projects';
import Footer from './Components/Footer';


function App() {
  return (
    <div>
      <Header />
      <Hero />
      <Projects />
      <Footer />
    </div>
    
  );
};

export default App;
