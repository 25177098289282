import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-white border-t border-gray-200 py-8">
      <div className="container mx-auto text-center">
        <p className="text-sm text-gray-600">&copy; 2024 Jeff Ocaya. All rights reserved.</p>
        <nav className="flex justify-center space-x-6 text-sm font-medium text-gray-700 mt-4">
          <a href="#projects" className="hover:text-blue-500">PROJECTS</a>
          <a href="#now" className="hover:text-blue-500 hidden">NOW</a>
          <a href="#blog" className="hover:text-blue-500 hidden">BLOG</a>
          <a href="#" className="hover:text-blue-500">ABOUT</a>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
