import React from "react";
import Accordion from "./Accordion";

const Projects = () => {
  return (
    <div id="projects" className="pt-12">
      <section className="lg:py-24 lg:px-60 py-12 px-8 bg-gray-50 mt-4 ">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-left mb-4">Projects</h2>

          <Accordion title="All Free Spaces - Free Workspace Directory">
            <p>
              <strong>What it is:</strong> Developed a free workspace directory;
              a platform for entrepreneurs, students and researchers to discover
              public and community libraries, organizations, hotels and coffee
              shops near them that allow them to use their workspaces for free.
              Currently available in the US and Uganda.
            </p>
            <p>
              <strong>Why this Project?</strong> Coming from a humble background
              I couldn’t afford a computer, internet and office workspaces.
              Luckily there were organizations in my community that allowed
              people like myself to come in and use their resources. That is how
              I was able to learn computer skills and eventually learn
              programming.
            </p>
            <p className="hidden">
              <strong>Local Impact:</strong> The site also has 20 Free Spaces
              listed from Uganda that are aimed at helping students, researchers
              and budding entrepreneurs find places to study, work and research
              for free. I personally have 6 of these spaces.
            </p>
            <p className="hidden">
              <strong>International Impact:</strong> The site has 200+ spaces
              added from the top tech hub states of the USA including
              California, New York, Virginia, Florida, Boston, Illinois,
              Chicago, Georgia, North Carolina and Maryland. Popular among tech
              entrepreneurs and employees.
            </p>
            <p>
              <a
                href="https://allfreespacesite.vercel.app"
                target="_blank"
                className="text-blue-500 underline"
              >
                Project Link
              </a>
            </p>
          </Accordion>

          <Accordion title="NestGenerate - Nest Js Boilerplate Generator">
            <p>
              <strong>What it is:</strong> Collaborated with another developer{" "}
              <span>
                <a
                  href="https:github.com/ogwok"
                  target="_blank"
                  className="text-blue-500 underline"
                >
                  Ogwok William Bruno
                </a>
              </span>{" "}
              on the development of an online tool for generating Nest Js
              Boilerplate from DBML. I worked on the UX/UI design of the site as
              William worked on the backend.
            </p>
            <p>
              <strong>Why this Project?</strong> Having been a web developer for
              5 years, William realized that this particular process of having
              to generate Nest Js Boilerplate from scratch again and again so he
              decided it's time he makes a tool to automate this. And so he
              asked me to help build the Front End of the project and bring it
              to life.
            </p>
            <p>
              <strong>Impact:</strong> This tool helps developers save time and
              streamline their development workflow when developing backend.
            </p>
            <p></p>
          </Accordion>

          <Accordion title="Skeelsy - Local skills Training MarketPlace (Front End)">
            <p>
              <strong>What it is:</strong> Developed front end for a marketplace
              for skills training, a place for people to list and find skills
              training classes in their neighborhood in the category of fitness,
              cooking, music, dance classes etc.
            </p>
            <p>
              <strong>Why this project:</strong> The idea is to help people who
              travel or move to new places find and meet people with similar
              interests via these in person classes around them.
            </p>
            <p>
              <strong>Impact:</strong> this helps local trainers and educators
              with the platform to market their classes and the users to find
              these classes.
            </p>
            <p>
              <a
                href="https://skeelsy.vercel.app"
                target="_blank"
                className="text-blue-500 underline"
              >
                Project link
              </a>
            </p>
          </Accordion>

          <Accordion title="Vethype - Endorsement Website(Front End)">
            <p>
              <strong>What it is:</strong> Developed UI for an endorsement
              website like TrustPilot but for Professionals. It’s a place to
              leave endorsements on professionals you have worked with.
            </p>
            <p>
              <strong>Why this Project:</strong> The idea is to make it easy for
              people to find trusted professionals to work with. With a website
              the vethype, you can see what people say about the experience of
              working with service providers in your locality before you work
              with them.
            </p>
            <p>
              <strong>Impact:</strong> Digitize the good old word of mouth
              recommendations helping reputable service providers get more
              business and people hiring professionals not get burned by the bad
              ones.
            </p>
            <p>
              <a
                href="https://vethype.com"
                target="_blank"
                className="text-blue-500 underline"
              >
                Project Link
              </a>
            </p>
          </Accordion>

          <Accordion title="Volunteer at Tech Team | Watoto Church Ministry (Uganda)">
            <p>
              <strong>Duration:</strong> May 2024 - Present
            </p>
            <p>
              Working with propresenter software and sound software to
              facilitate church services by controlling Visual and audio
              experiences and maintenance of tech tools.
            </p>
            <p>
              Team collaboration with other volunteers and church leaders in
              facilitation of church activities.
            </p>
            <p>
              <a
                href="https://watotochurch.com"
                target="_blank"
                className="text-blue-500 underline"
              >
                Watoto Church Website
              </a>
            </p>
          </Accordion>
        </div>
      </section>
    </div>
  );
};

export default Projects;
