import React from 'react';

const Header = () => {
  return (
    <header className="bg-white border-b border-gray-200 py-4 text-xl sticky top-0">
      <div className="container mx-auto flex items-center justify-between px-4">
        <div className="flex items-center font-bold text-2xl text-blue-500">
          Jeff Ocaya
        </div>
        <nav className="flex items-center space-x-6 text-sm font-medium text-gray-700">
          <a href="#projects" className="hover:text-blue-500">PROJECTS</a>
          <a href="#" className="hover:text-blue-500 hidden">NOW</a>
          <div className="relative group hidden">
            <button className="hover:text-blue-500 flex items-center">
              NOW <span className="ml-1 hidden">&#9662;</span>
            </button>
            <div className="absolute left-0 hidden group-hover:block bg-white border border-gray-200 mt-2 py-2 shadow-lg">
              <a href="#" className="block px-4 py-2 hover:bg-gray-100">Blog</a>
              <a href="#" className="block px-4 py-2 hover:bg-gray-100">Book Notes</a>
            </div>
          </div>
          <a href="#" className="hover:text-blue-500 hidden">BLOG</a>
          <a href="#" className="hover:text-blue-500">ABOUT</a>
          <div className="relative group hidden">
            <button className="hover:text-blue-500 flex items-center">
              MORE <span className="ml-1">&#9662;</span>
            </button>
            <div className="absolute left-0 hidden group-hover:block bg-white border border-gray-200 mt-2 py-2 shadow-lg">
              <a href="#" className="block px-4 py-2 hover:bg-gray-100">Option 1</a>
              <a href="#" className="block px-4 py-2 hover:bg-gray-100">Option 2</a>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
